import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Chip, Paper, Typography } from '@mui/material';
import { Pill } from 'components/Pill';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { HomestayProvider } from 'types/homestay-provider.enum';
import { Curriculum, ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useAsync } from 'hooks/use-async';
import { useEffect, useMemo, useState } from 'react';
import { Country } from 'types/country.interface';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const SidebarInfoPanel = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const coedStatusText = profile.coedStatus
    ? {
        [CoedStatuses.Male]: 'Boys only',
        [CoedStatuses.Female]: 'Girls only',
        [CoedStatuses.CoEd]: 'Coeducational',
      }[profile.coedStatus]
    : null;
  const boardingStatusText = {
    day: 'Day school only',
    boarding: 'Boarding school only',
    dayAndBoarding: 'Day school with Boarding facilities',
  }[profile.dayOrBoarding];

  const religiousAffiliationText = profile.religiousAffiliation ? `${profile.religiousAffiliation} Affiliation` : 'No Religious Affiliation';

  const isInternationalCurriculum = (c: Curriculum) => ['International Baccalaureate', 'Cambridge International'].includes(c.name);
  const displayCurriculums = profile.curriculums.filter(isInternationalCurriculum).map((c) => c.name);

  // Highlight special homestay providers as pills instead of text.
  const homestayPillProviders = [HomestayProvider.Educatius, HomestayProvider.AustralianHomestayNetwork];
  const showHomestayProviderAsPill = homestayPillProviders.includes(profile.homestayAccomodation!);
  const homestayProviderEl = showHomestayProviderAsPill ? (
    <Pill label={profile.homestayAccomodation} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
  ) : (
    profile.homestayAccomodation
  );

  const [countries] = useAsync<Country[]>('/countries');
  const flagSrc = useMemo(() => {
    const country = countries?.find((country) => country.name === profile.country);
    return country ? `/flag-icons/${country.iso_3166_3}.svg` : '';
  }, [countries, profile]);

  const [isFlagError, setIsFlagError] = useState<boolean>(false);
  const isPdfFormat = useIsPdfFormat();

  useEffect(() => {
    const flagImage = new Image();
    flagImage.onerror = () => {
      setIsFlagError(true);
    };
    if (flagSrc) flagImage.src = flagSrc;
  }, [flagSrc]);

  // Sidebar collapses into section at top in pdf format
  const gridItemProps = { xs: 12, sm: 6, md: isPdfFormat ? 4 : 12 };

  return (
    <Paper>
      <Grid container sx={{ p: { xs: '20px', md: isPdfFormat ? '20px' : '12px' } }} spacing={1}>
        <Grid {...gridItemProps}>
          <Typography>
            {profile.city}
            {profile.state !== profile.city ? `, ${profile.state}` : ''}
          </Typography>
          <Chip
            label={profile?.country || ''}
            icon={isFlagError ? <></> : <img src={flagSrc} alt={`${profile?.country} flag`.trim()} width="16px" height="16px" />}
            size="small"
            sx={{ m: 1, backgroundColor: 'background.paper' }}
          />
        </Grid>
        {profile.homestayAccomodation && (
          <Grid {...gridItemProps}>
            <Typography>Homestay managed by</Typography>
            {homestayProviderEl}
          </Grid>
        )}
        {displayCurriculums.length > 0 && (
          <Grid {...gridItemProps}>
            <Typography>International curriculum:</Typography>
            {displayCurriculums.map((name) => (
              <Pill key={name} label={name} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
            ))}
          </Grid>
        )}
        <Grid {...gridItemProps}>
          <Typography>{profile.isPrivateSchool ? 'Private school' : 'Public school'}</Typography>
        </Grid>
        {boardingStatusText && (
          <Grid {...gridItemProps}>
            <Typography>{boardingStatusText}</Typography>
          </Grid>
        )}
        {coedStatusText && (
          <Grid {...gridItemProps}>
            <Typography sx={{ textTransform: 'capitalize' }}>{coedStatusText}</Typography>
          </Grid>
        )}
        {profile.totalStudentCount && (
          <Grid {...gridItemProps}>
            <Typography>{profile.totalStudentCount} total students</Typography>
          </Grid>
        )}
        {profile.averageClassSize && (
          <Grid {...gridItemProps}>
            <Typography>{profile.averageClassSize} average class size</Typography>
          </Grid>
        )}
        {profile.internationalStudentPercentage && (
          <Grid {...gridItemProps}>
            <Typography>{profile.internationalStudentPercentage}% international students</Typography>
          </Grid>
        )}
        <Grid {...gridItemProps}>
          <Typography>{religiousAffiliationText}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
