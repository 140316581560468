import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { HighlightAvatar } from 'components/HighlightAvatar';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const HighlightsSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ p: '20px', mb: '10px' }}>
        <Typography mb={3} variant="h5" fontSize="24px" fontWeight={700} color={color}>
          Highlights
        </Typography>
        <Grid container spacing={4} px={2}>
          <Grid xs={12} sm={4}>
            <Stack alignItems="center">
              <HighlightAvatar icon={profile.highlightIcon1} iconScale={4} width={160} height={160} color={color} />
              <Typography textAlign="center" mt={1}>
                {profile.highlightText1}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} sm={4}>
            <Stack alignItems="center">
              <HighlightAvatar icon={profile.highlightIcon2} iconScale={4} width={160} height={160} color={color} />
              <Typography textAlign="center" mt={1}>
                {profile.highlightText2}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} sm={4}>
            <Stack alignItems="center">
              <HighlightAvatar icon={profile.highlightIcon3} iconScale={4} width={160} height={160} color={color} />
              <Typography textAlign="center" mt={1}>
                {profile.highlightText3}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </section>
  );
};
