import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppBar, Box, Link, Popover, Stack, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import EducatiusLogo from 'assets/images/logo-educatius-header.svg';
import { UserMenuIconButton } from 'components/UserMenuIconButton';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useMatch, useNavigate } from 'react-router-dom';
import { getConfig } from 'utils/config';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { EducatiusProductsNav } from './EducatiusProductsNav';

export const EducatiusHeader = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { educatiusResourcesBasePath } = getConfig();
  const educatiusNewsPath = `${educatiusResourcesBasePath}/news`;
  const educatiusSupportPath = `${educatiusResourcesBasePath}/support`;
  const educatiusResourcesPath = `${educatiusResourcesBasePath}/resources`;

  const isPdfFormat = useIsPdfFormat();
  const isSharedView = useMatch('/marketing/profiles/:profileId/shared-view/p/:region/u/:userId/vp/:viewPricing');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const sendGAEvent = (eventType: string) => {
    ReactGA.event({ category: 'Nav Link', label: `Educatius Header: ${eventType}`, action: 'Clicked' });
  };

  const background = theme.palette.primary.dark;
  const hoverBgColor = '#FF500044';
  const activeBgColor = '#FE5000';
  const activeBorderBottom = `5px solid ${activeBgColor}`;

  const linkHoverSx: SxProps<Theme> = {
    bgcolor: hoverBgColor,
    transition: 'all 0.3s ease',
  };

  const linkBoxSx: SxProps<Theme> = {
    cursor: 'pointer',
    textDecoration: 'none',
    height: '90px',
    borderBottom: `5px solid ${background}`, // Holds space in dom while inactive
    '&:hover': {
      ...linkHoverSx,
      borderBottom: activeBorderBottom,
    },
    mx: 2,
    px: 2,
  };
  const activeLinkBoxSx: SxProps<Theme> = {
    ...linkBoxSx,
    bgcolor: hoverBgColor,
    borderBottom: activeBorderBottom,
  };
  const iconSx: SxProps<Theme> = {
    width: '24px',
    height: '24px',
    fontSize: '24px',
    color: theme.palette.primary.contrastText,
  };
  const userMenuButtonSx: SxProps<Theme> = {
    '&:hover': linkHoverSx,
    borderRadius: '0.5rem',
    width: '24px',
    height: '24px',
    p: '20px',
  };

  return (
    <AppBar sx={{ zIndex: 100, boxShadow: 0, border: 0 }}>
      <Box
        sx={{
          pr: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background,
        }}
      >
        <ButtonBase>
          <Link href={educatiusResourcesBasePath} sx={{ height: 30, m: '30px' }}>
            <img src={EducatiusLogo} alt="Educatius logo" height="30px" />
          </Link>
        </ButtonBase>

        {!isSharedView && !isPdfFormat && (
          <>
            <Box flex={1}></Box>
            <ButtonBase
              sx={{ ...linkBoxSx, borderBottom: activeBorderBottom }}
              onClick={() => {
                sendGAEvent('Browse schools');
                navigate('/marketing');
              }}
              data-intercom-target="browse schools"
            >
              <Stack height="90px" direction="column" justifyContent="center">
                <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                  Schools
                </Typography>
              </Stack>
            </ButtonBase>
            <ButtonBase
              sx={anchorEl ? activeLinkBoxSx : linkBoxSx}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                sendGAEvent('Browse products');
              }}
              data-intercom-target="browse products"
            >
              <Stack height="90px" direction="column" justifyContent="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    Products
                  </Typography>
                  <ExpandMoreIcon />
                </Stack>
              </Stack>
            </ButtonBase>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <EducatiusProductsNav />
            </Popover>
            <ButtonBase
              data-intercom-target="resources button"
              onClick={(e) => {
                sendGAEvent('Resources');
              }}
            >
              <Link sx={linkBoxSx} href={educatiusResourcesPath}>
                <Stack height="90px" direction="column" justifyContent="center">
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    Resources
                  </Typography>
                </Stack>
              </Link>
            </ButtonBase>
            <ButtonBase
              data-intercom-target="news button"
              onClick={(e) => {
                sendGAEvent('News');
              }}
            >
              <Link sx={linkBoxSx} href={educatiusNewsPath}>
                <Stack height="90px" direction="column" justifyContent="center">
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    News
                  </Typography>
                </Stack>
              </Link>
            </ButtonBase>
            <ButtonBase
              data-intercom-target="help center button"
              onClick={(e) => {
                sendGAEvent('Help center');
              }}
            >
              <Link sx={linkBoxSx} href={educatiusSupportPath}>
                <Stack height="90px" direction="column" justifyContent="center">
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    Support
                  </Typography>
                </Stack>
              </Link>
            </ButtonBase>
            <Stack direction="row" ml={1.8} spacing={0.9} alignItems="center">
              {/* The tooltip should sit beneath the use meny dropdown menu which has zIndex 1200 */}
              <Tooltip
                title="User menu"
                arrow
                PopperProps={{ style: { zIndex: 1000 } }}
                onClick={(e) => {
                  sendGAEvent('User menu');
                }}
              >
                <div>
                  <UserMenuIconButton sx={userMenuButtonSx} activeSx={{ bgcolor: activeBgColor }}>
                    <AccountCircleOutlinedIcon sx={iconSx} />
                  </UserMenuIconButton>
                </div>
              </Tooltip>
            </Stack>
          </>
        )}
      </Box>
    </AppBar>
  );
};
