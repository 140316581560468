import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';

export const TestimonialsSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ p: '20px', mb: '10px' }}>
        <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor} mb={3}>
          Testimonials
        </Typography>
        <Stack spacing={3}>
          {profile.testimonials.map((testimonial) => (
            <Stack key={testimonial.name} direction="row" spacing={2}>
              <img src="/testimonial-icon-grey.png" alt="Quotation marks" style={{ width: '80px', height: '80px' }} />
              <Stack>
                <Typography variant="h6" fontWeight="500" textTransform="capitalize">
                  {testimonial.name}
                </Typography>
                <Typography textTransform="capitalize" fontWeight="bold" color="primary" mb={1}>
                  {testimonial.type}
                </Typography>
                <Typography>{testimonial.quote}</Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Paper>
    </section>
  );
};
