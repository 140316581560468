import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { getCountryGrades, getCountryGradingSystem } from 'utils/countryGradingSystems';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const AcademicSection = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  const displayIntlCurriculums = profile.curriculums.length > 0;
  const displayDomesticCurriculums = profile.domesticCurriculums?.length > 0;
  const intlCurriculums = profile.curriculums.map((c) => c.name).join(', ');

  const hasAgeRange = profile.agesAcceptedMin || profile.agesAcceptedMax;
  const ageRange = `${profile.agesAcceptedMin || 'Any'}-${profile.agesAcceptedMax || 'Any'}`;

  const gradingSystem = getCountryGradingSystem(profile.country);
  const grades = getCountryGrades(profile.country);
  const minGrade = grades.find((grade) => grade.equivalentAge === profile.minAcceptedGradeAge);
  const maxGrade = grades.find((grade) => grade.equivalentAge === profile.maxAcceptedGradeAge);
  const hasGrades = minGrade && maxGrade;
  const gradesAccepted = `${minGrade?.name} to ${maxGrade?.name}`;

  const hasAcceptsGraduatedStudents = typeof profile.acceptsGraduatedStudents === 'boolean';
  const hasIsPlus1School = typeof profile.isPlus1School === 'boolean';

  return (
    <section>
      <Paper sx={{ marginBottom: '10px' }}>
        <Box style={{ padding: '20px' }}>
          <Typography variant="h5" mb={2} fontSize="24px" fontWeight={700} color={titleColor}>
            Academic
          </Typography>
          <Grid container rowSpacing={4}>
            <Grid xs={12} md={6}>
              <Stack spacing={1}>
                <Typography fontWeight="600">Admission requirements</Typography>
                {hasAgeRange && (
                  <Box>
                    <Typography display="inline">Ages accepted: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {ageRange}
                    </Typography>
                  </Box>
                )}
                {hasGrades && (
                  <Box>
                    <Typography display="inline">{`Accepting ${gradingSystem.prefix.toLowerCase()}: `}</Typography>
                    <Typography display="inline" fontWeight="bold">
                      {gradesAccepted}
                    </Typography>
                  </Box>
                )}
                {profile.aeasScoreMin && (
                  <Box>
                    <Typography display="inline">AEAS: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.aeasScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.cambridgeScoreMin && (
                  <Box>
                    <Typography display="inline">Cambridge: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.cambridgeScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.eltisScoreMin && (
                  <Box>
                    <Typography display="inline">ELTIS 2: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.eltisScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.idatScoreMin && (
                  <Box>
                    <Typography display="inline">IDAT: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.idatScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.ieltsScoreMin && (
                  <Box>
                    <Typography display="inline">IELTS: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.ieltsScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.pearsonScoreMin && (
                  <Box>
                    <Typography display="inline">Pearson: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.pearsonScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.toeflScoreMin && (
                  <Box>
                    <Typography display="inline">TOEFL: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.toeflScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.duolingoScoreMin && (
                  <Box>
                    <Typography display="inline">Duolingo: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.duolingoScoreMin}
                    </Typography>
                  </Box>
                )}
                {profile.interview && (
                  <Box>
                    <Typography display="inline">Interview: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.interview}
                    </Typography>
                  </Box>
                )}
                {hasAcceptsGraduatedStudents && (
                  <Box>
                    <Typography display="inline">Accepts graduate students: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.acceptsGraduatedStudents ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                )}
                {profile.hostFamilyRequiredBefore && (
                  <Box>
                    <Typography display="inline">Host family requirements: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.hostFamilyRequiredBefore}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid xs={12} md={6} spacing={1}>
              <Stack spacing={1}>
                <Typography fontWeight="600">Academic stats</Typography>
                <Box>
                  <Typography display="inline">Diploma year offered: </Typography>
                  <Typography display="inline" fontWeight="bold">
                    {profile.usOneYearDiploma ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                {profile.averageSatScore && (
                  <Box>
                    <Typography display="inline">Average SAT score: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.averageSatScore}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography display="inline">English as Second Language (ESL) support: </Typography>
                  <Typography display="inline" fontWeight="bold">
                    {profile.englishLangSupport ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                {displayIntlCurriculums && (
                  <Box>
                    <Typography display="inline">Curriculum offered: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {intlCurriculums}
                    </Typography>
                  </Box>
                )}
                {displayDomesticCurriculums && (
                  <Box>
                    <Typography display="inline">Domestic Curriculum offered: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.domesticCurriculums.join(', ')}
                    </Typography>
                  </Box>
                )}
                {hasIsPlus1School && (
                  <Box>
                    <Typography display="inline">1+1 School: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.isPlus1School ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                )}
                {profile.sportsParticipationCompetitiveness && (
                  <Box>
                    <Typography display="inline">Sports Participation Competitiveness: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.sportsParticipationCompetitiveness}
                    </Typography>
                  </Box>
                )}
                {profile.totalStudentCount && (
                  <Box>
                    <Typography display="inline">Total Student Roll: </Typography>
                    <Typography display="inline" fontWeight="bold">
                      {profile.totalStudentCount}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {!isSharedView && profile.specialNotes && (
        <Paper sx={{ marginBottom: '10px' }}>
          <Box style={{ padding: '20px' }}>
            <Typography variant="h6" color="secondary" mb={1}>
              Special Notes
            </Typography>
            <Typography color="secondary">{profile.specialNotes}</Typography>
          </Box>
        </Paper>
      )}
    </section>
  );
};
