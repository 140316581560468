import { Grid } from '@mui/material';
import { ImageWithSkeleton } from 'components/ImageWithSkeleton';
import { IFile } from 'types/file.interface';
import { ImageEditOptions, getOptimisedImageUrl } from 'utils/getOptimisedImageUrl';

interface ImageGridProps {
  imageFiles: IFile[];
}

export const ImageGrid = ({ imageFiles }: ImageGridProps) => {
  const imageEditOptions: ImageEditOptions = {
    resize: { width: 600, fit: 'cover' },
  };

  return (
    <Grid container spacing={2}>
      {imageFiles.slice(0, 8).map((f) => (
        <Grid item xs={6}>
          <ImageWithSkeleton src={getOptimisedImageUrl(f.fileUrl!, imageEditOptions)} alt="banner" style={{ objectFit: 'cover' }} width="100%" height="100%" />
        </Grid>
      ))}
    </Grid>
  );
};
